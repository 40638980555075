::-webkit-input-placeholder {
    font-style: italic;
}

:-moz-placeholder {
    font-style: italic;
}

::-moz-placeholder {
    font-style: italic;
}

:-ms-input-placeholder {
    font-style: italic;
}

body {
    padding-bottom: 40px;
    min-height: calc(100vh - 80px);
}

.content-page {
    min-height: 100%;
    overflow: visible;
}

#wrapper {
    overflow: visible;
}

.logo-auth {
    width: 200px;
    margin: 15px auto;
}

.invalid-feedback {
    color: #ff5c75;
    font-size: 80%;
    margin-top: 0;
    font-style: italic;
}

.cursor-link {
    cursor: pointer;
}

div.dataTables_wrapper div.dataTables_processing {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 200px;
    text-align: center;
    background-color: #c3272f;
    background-position: 15px center;
    background-repeat: no-repeat;
    border-radius: 3px;
    box-shadow: 0 0 12px #ced4da;
    color: #ffffff;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=95);
    filter: alpha(opacity=95);
    opacity: 0.95;
    margin: 0 0 6px;
    overflow: visible;
    padding: 15px 25px 15px 25px;
    pointer-events: auto;
    z-index: 6000;
}

.datatable-top-wrap,
.datatable-bottom-wrap {
    display: flex;
    justify-content: space-between;
}

.dropdown-item {
    color: #212529;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-radius: 0.3rem 0.3rem 0 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-radius: 0 0 0.3rem 0.3rem !important;
}

.ck.ck-toolbar,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: #e2e7f1 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.1rem;
}

.has-errors .select2-container .select2-selection--single {
    border: 1px solid #ff5c75;
}

.has-errors .invalid-feedback {
    color: #ff5c75;
    display: block;
}

.dropzone.has-errors {
    border: 2px dashed #ff5c75;
}

.select2-results__option[aria-selected="true"] {
    color: #cccccc !important;
    font-style: italic !important;
}

.select2-selection__clear {
    margin-right: 15px;
}

.pt-6 {
    padding-top: 6px;
}

.available-file .feather {
    height: 1.3em;
    width: 1.3em;
    margin-left: 4px;
}

.input-group .feather {
    height: 1.3em;
    width: 1.3em;
}

.form-inline .date-range .form-control {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.bootstrap-tagsinput {
    border: 1px solid #e2e7f1;
    border-radius: 0.3rem;
    box-shadow: none;
    color: #4b4b5a;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    min-height: calc(1em + 1rem + 2px);
    line-height: 1.8;
    padding: 6px 0.75rem;
}

.bootstrap-tagsinput .tag {
    background-color: #c3272f;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    margin-top: 6px;
    padding: 0 7px;
}

.btn-filter1 {
    background-color: #cdd36a;

    &:hover {
        background-color: #cdd36a;
        color: #ffffff;
    }
}

.btn-filter2 {
    background-color: #ff7f5a;

    &:hover {
        background-color: #ff7f5a;
        color: #ffffff;
    }
}

.btn-filter3 {
    background-color: #ffbe36;

    &:hover {
        background-color: #ffbe36;
        color: #ffffff;
    }
}

.btn-filter4 {
    background-color: #775dcc;

    &:hover {
        background-color: #775dcc;
        color: #ffffff;
    }
}

.popover {
    border: 1px solid #e2e7f1;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.2rem;
}

.custom-control.custom-radio {
    height: 39px;
    padding: 0.5rem 0 0.5rem 1.5rem;
}

span.error {
    color: #ff5c75;
    display: block;
    font-size: 80%;
    margin-top: 0.25rem;
    width: 100%;
}

.delete_confirm {
    display: none;
    font-style: italic;
}

.table td .form-group {
    margin-bottom: 0;
}

.text-red {
    color: red;
}

.text-green {
    color: green;
}

.modal-body {
    text-align: left !important;
}

.saved-filter {
    background-color: #faffbd;
}

.badge-wearer {
    background-color: #43d39e;
    color: #ffffff;
}

.badge-employee {
    background-color: #25c2e3;
    color: #ffffff;
}

.badge-lockerunit {
    background-color: #ff5c75;
    color: #ffffff;
}

.badge-storage {
    background-color: #ffbe0b;
    color: #ffffff;
}

.badge-preparation {
    background-color: #4e8a89;
    color: #ffffff;
}

.badge-pool {
    background-color: #ad7000;
    color: #ffffff;
}

.article-preparation-notification {
    background-color: #f1e1b6;
}

.article-preparation-warning {
    background-color: #f4dede;
}

.draggable,
.draggable-all {
    position: absolute !important;
    border-radius: 50%;
    z-index: 6000;
    font-size: 7px;
    color: #ffffff;
    padding: 0;
    cursor: move;
    text-align: center;
    background-color: rgba(192, 32, 44, 0.75);
}

.draggable-all {
    z-index: 10;
    cursor: default;
}

.draggable span,
.draggable-all span {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 15px;
    color: #2c2926;
    z-index: 6001;
    cursor: pointer;
}

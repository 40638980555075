@tailwind base;

@tailwind components;

@tailwind utilities;

@import "custom/fonts/fonts";

@import "~animate.css/source/_base.css";

@import "~animate.css/source/zooming_entrances/zoomIn.css";

@import "custom";
